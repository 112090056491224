import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["headline", "answer"];

  connect() {
    console.log("faq speaking here…");
  }
  toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("toggle faq");
    this.answerTarget.classList.toggle("d-none");
  }
}
