import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "anchor",
    "image",
    "prev",
    "next",
    "modal",
    "closebutton",
    "lightboximage",
    "lightboxdescription",
    "counter",
  ];

  connect() {
    this.currentImage = 0;
    console.log("did connect lightbox" + this.currentImage);
  }
  showimage(e) {
    let img_anchor = this.getParent(e.target, "a", "tagname");

    console.log(
      "show image " +
        e.target +
        " with index " +
        img_anchor.dataset.lightboxIndex,
    );
    e.stopPropagation();
    e.preventDefault();

    this.currentImage = img_anchor.dataset.lightboxIndex;
    this.setCounter();
    this.setImageFromAnchor(img_anchor);

    this.modalTarget.classList.remove("d-none");
    this.modalTarget.style.display = "block";
    this.modalTarget.querySelector("#lightbox").display = "block";
  }

  setImageFromAnchor(img_anchor) {
    this.lightboximageTarget.setAttribute(
      "src",
      img_anchor.getAttribute("href"),
    );
    this.lightboxdescriptionTarget.innerHTML = img_anchor.getAttribute("title");
  }

  previousimage(e) {
    e.preventDefault();
    this.currentImage =
      this.currentImage - 1 < 0
        ? this.anchorTargets.length - 1
        : this.currentImage - 1;
    console.log(
      "show previous image " +
        this.currentImage +
        "of " +
        this.anchorTargets.length,
    );
    this.setCounter();
    let targetAnchor = this.anchorTargets[this.currentImage];
    this.setImageFromAnchor(targetAnchor);
  }

  setCounter() {
    this.counterTarget.innerHTML =
      "Bild " +
      (Number(this.currentImage) + 1) +
      " von " +
      this.anchorTargets.length;
  }
  nextimage(e) {
    e.preventDefault();
    this.currentImage =
      Number(this.currentImage) + 1 >= this.anchorTargets.length
        ? 0
        : Number(this.currentImage) + 1;
    console.log(
      "show next image " +
        this.currentImage +
        "of " +
        this.anchorTargets.length,
    );

    this.setCounter();
    let targetAnchor = this.anchorTargets[this.currentImage];
    this.setImageFromAnchor(targetAnchor);
  }
  closemodal() {
    console.log("now close");
    this.modalTarget.classList.add("d-none");
    this.modalTarget.style.display = "none";
  }
  getParent(tester, test_criterium, test_class = "class", test_value) {
    while (tester != null) {
      if (test_class == "tagname") {
        // console.log("test tagname: " + tester.tagName.toUpperCase())
        if (tester.tagName == test_criterium.toUpperCase()) {
          return tester;
        }
      }
      if (test_class == "class") {
        if (
          tester.classList != null &&
          tester.classList.contains(test_criterium)
        ) {
          return tester;
        }
      }
      if (test_class == "dataset") {
        if (
          test_criterium in tester.dataset === true &&
          test_value == undefined
        ) {
          return tester;
        }
        if (
          test_criterium in tester.dataset === true &&
          test_value == tester.dataset[test_criterium]
        ) {
          return tester;
        }
      }
      tester = tester.parentElement;
    }
    return false;
  }
}
