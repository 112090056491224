import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["form", "input", "content", "md5"];

  connect() {
    let request_pwd = this.getUrlParameter("pwd_protect");
    if (request_pwd) {
      this.append_md5_hidden_input(request_pwd);
    }
  }

  validate(e) {
    e.preventDefault();
    let pwd = this.inputTarget.value;
    if (!pwd) {
      alert("Bitte geben Sie ein Kennwort ein!");
      return false;
    }
    this.pwd_show(pwd);
  }

  pwd_show(pwd) {
    let md5_pwd_input = md5(pwd);

    if (md5_pwd_input == this.md5Target.value) {
      this.contentTarget.classList.remove("d-none");
      this.formTarget.classList.add("d-none");
      this.append_md5_hidden_input(pwd);
    } else {
      alert("Bitte geben Sie das richtige Kennwort ein!");
    }
  }

  append_md5_hidden_input(pwd) {
    let forms = document.querySelectorAll("form");
    for (var i = 0; i < forms.length; i++) {
      let f = forms[i];
      f.append('<input name="pwd_protect" type="hidden" value="' + pwd + '">');
      f.setAttribute(
        "action",
        f.getAttribute("action") + "&pwd_protect=" + pwd,
      );
    }
  }

  getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
  }
}
